import { Component, HostListener, OnInit } from '@angular/core';
import { VehicleService } from './shared/services/vehicle/vehicle.service';
import { catchError, EMPTY } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageService } from './shared/services/storage/storage.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'Connexion Rental';

  constructor(private vehicleService: VehicleService, private storageService: StorageService, private spinner: NgxSpinnerService, private titleService: Title) {
    this.titleService.setTitle(this.title);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event): void {
    this.vehicleService.reservationDetails$.subscribe((reservationDetails) => {
      if (reservationDetails) {
        localStorage.setItem('reservation', JSON.stringify(reservationDetails));
      }
    });
  }

  async ngOnInit(): Promise<void> {
    const hostParts = document.location.host.startsWith('www') ? document.location.host.split('.').slice(1) : document.location.host.split('.');
    const clearDealerGroup = () => {
      document.location.href = document.location.host.includes('localhost') ? `http://${hostParts.slice(1).join('.')}` : `https://${document.location.host.startsWith('www') ? 'www.' : ''}${hostParts.slice(1).join('.')}`;
      this.storageService.setStorageItem({
        key: 'dealerGroup',
        value: '',
        storageArea: 'sessionStorage',
      })
      this.titleService.setTitle(this.title);
    }
    
    if ((document.location.host.includes('localhost') && hostParts.length > 1) || hostParts.length > 3) {
      this.spinner.show();
      this.vehicleService
        .getDealerGroup(hostParts[0])
        .pipe(
          catchError((err: any) => {
            console.error(err);
            clearDealerGroup();
            this.spinner.hide();
            return EMPTY;
          })
        )
        .subscribe((dealerGroup) => {
          if (dealerGroup) {
            this.storageService.setStorageItem({
              key: 'dealerGroup',
              value: JSON.stringify(dealerGroup),
              storageArea: 'sessionStorage',
            })

            if(dealerGroup.title) {
              this.titleService.setTitle(dealerGroup.title);
            }

            setTimeout(() => {
              this.spinner.hide();
            }, 5000);

            return;
          }
          clearDealerGroup();
        });
    }
    
    const reservationDetails = localStorage.getItem('reservation');
    if (reservationDetails) {
      this.vehicleService.updateReservation(JSON.parse(reservationDetails));
    }
  }
}
