import { CustomerInfo } from '../../models/customer-info.types';

export interface Reservation {
  reservationId: string;
  startDateTime: string;
  endDateTime: string;
  state: string;
  [key: string]: any;
}

export interface FilterOption {
  id: string;
  name: string;
  value: string;
}

export interface VehiclePrice {
  type: 'daily_rate' | 'weekly_rate' | 'monthly_rate';
  value: string;
  id: string;
}

export interface FilterVehicleMetaDataResponse {
  meta: Record<string, FilterOption[]>;
}

interface _Vehicle {
  imageUrl: string[];
  dealerId: string;
  vin: string;
  priority: string;
  make: string;
  state: string;
  year: string;
  color: string;
  trim: string;
  description: string;
  customizations: string;
  fuelType: string;
  fuelCap: string;
  passengerCap: string;
  bootCap: string;
  category: string;
  type: string;
  drive: string;
  fuelEcon: string;
  model: string;
  features?: string[];
  transmissionType: string;
  reservations: Reservation[];
}

/** API Responses */

export interface VehicleResponse extends _Vehicle {
  prices: VehiclePrice[];
  location: {
    lat: number;
    lon: number;
  };
}

export type BrowserVehicleResponse = {
  count: number;
  lastEvaluatedKey?: string;
  vehicles: VehicleResponse[];
};

export interface ReserveVehicleResponse {
  statusCode: number;
  message: string;
  data?: {
    isTestDrive: boolean;
    id: string;
    createdAt: string;
  };
}

export interface BusinessHour {
  day: string;
  opening: string | null;
  closing: string | null;
  status: 'OPENS' | 'CLOSED';
  _id: string;
}

export interface OpeningHour {
  day: string;
  hours: string;
}

export interface Dealer {
  _id: string;
  name: string;
  address: string;
  zip: string;
  city: string;
  state: string;
  settings: {
    dealerLogoUrl: string;
    businessHours: {
      message: string;
      entries: BusinessHour[];
    };
  };
}

export type FilterParams = Record<string, string[] | string | number>;
export interface QueryParam {
  pickupDate: string;
  pickupTime: string;
  returnDate: string;
  returnTime: string;
  renterAge: string;
  lat: number;
  lng: number;
  location: string;
  radius: number;
  limit: number;
  dealerId?: string;
  filters?: FilterParams;
  dealerGroup?: string;
}

export interface QueryParamWithReservationDetails extends QueryParam {
  reservationId: string;
  vin: string;
  dealerId: string;
}
export interface ReservationDetails {
  reservationId?: string;
  reservationAddress?: string;
  totalPrice?: number;
  amountReceived?: number;
  vehicle?: VehicleViewModel;
  customer?: CustomerInfo;
  createdAt?: string;
  reservationNumber?: string;
  isTestDrive?: boolean;
  queryParams: QueryParam | QueryParamWithReservationDetails;
}

export type ReservationState = 'pending' | 'cancelled' | 'reserved';
export interface ReservationDetailsResponse {
  reservation: {
    state: ReservationState;
    startDateTime: string;
    endDateTime: string;
    totalPrice: number;
    amountReceived: number;
    createdAt: string;
    reservationNumber: string;
    isTestDrive?: boolean;
  };
  vehicle: VehicleResponse;
  customer: CustomerInfo | null;
}

/** API Request */
export interface ReserveVehicleRequest {
  pickuplatitude: number;
  pickuplongitude: number;
  returnlatitude: number;
  returnlongitude: number;
  startDateTime: string;
  endDateTime: string;
  dealerId: string;
  vin: string;
  dealerGroup?: string;
}

/** View Models */

export interface DealerViewModel {
  dealerId: string;
  name: string;
  address: string;
  settings: {
    dealerLogoUrl: string;
    openingHours: BusinessHour[];
  };
}

export interface DealerGroup {
  group: string;
  title: string;
  dealerIds: string[];
  banner: string;
  hero: string;
  bannerLeft: string;
  bannerRight: string;
  isTestDrive: boolean;
}

export interface VehicleViewModel extends _Vehicle {
  totalPrice: number;
  reservationId?: string;
  dailyPrice: number;
  weeklyPrice: number;
  monthlyPrice: number;
  location: {
    lat: number;
    lng: number;
  };
}

export enum ReservationStateEnum {
  CONFIRM_AND_PAY = 'CONFIRM_AND_PAY',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
}

export interface DealerImageResponse {
  _id: string;
  dealerId: string;
  vin: string;
  promoImages: string[];
}

export const DEFAULT_ZOOM_LEVEL = 11;
export const DEFAULT_LIMIT = 25;
export const ZOOM_LEVEL_TO_METER: Record<number, number> = {
  8: 251578,
  9: 190146,
  10: 95340,
  11: 47346,
  12: 15898,
  13: 7952,
};

export const DEALER_NOT_FOUND_MOCK: Dealer = {
  _id: Math.random().toString(),
  name: 'Dealer Name',
  address: 'address',
  zip: 'zip',
  city: 'City',
  state: 'State',
  settings: {
    businessHours: {
      entries: [
        {
          day: 'MONDAY',
          opening: null,
          closing: null,
          status: 'CLOSED',
          _id: '6556aa4ab7126872fc62132e',
        },
        {
          day: 'TUESDAY',
          opening: '09:00',
          closing: '17:30',
          status: 'OPENS',
          _id: '6556aa4ab7126872fc62132f',
        },
        {
          day: 'WEDNESDAY',
          opening: '09:00',
          closing: '17:30',
          status: 'OPENS',
          _id: '6556aa4ab7126872fc621330',
        },
        {
          day: 'THRUSDAY',
          opening: '09:00',
          closing: '17:30',
          status: 'OPENS',
          _id: '6556aa4ab7126872fc621331',
        },
        {
          day: 'FRIDAY',
          opening: '09:00',
          closing: '17:30',
          status: 'OPENS',
          _id: '6556aa4ab7126872fc621332',
        },
        {
          day: 'SATURDAY',
          opening: null,
          closing: null,
          status: 'CLOSED',
          _id: '6556aa4ab7126872fc621333',
        },
        {
          day: 'SUNDAY',
          opening: null,
          closing: null,
          status: 'CLOSED',
          _id: '6556aa4ab7126872fc621334',
        },
      ],
      message: 'Holiday Wishes to Everyone!',
    },
    dealerLogoUrl: 'https://dev-api.cxztrac.com/dealers/s3/119219/logo.png',
  },
};
