/* istanbul ignore file */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  finalize,
  forkJoin,
  map,
  of,
  tap,
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  HandleFetchVehiclesInNewLocationParams,
  getFormattedDateTime,
  getTotalPrice,
} from '../../../features/vehicle/utils/vehicle.component.utils';
import { CustomerInfo } from '../../models/customer-info.types';
import {
  BrowserVehicleResponse,
  DEALER_NOT_FOUND_MOCK,
  DEFAULT_LIMIT,
  Dealer,
  DealerGroup,
  DealerImageResponse,
  DealerViewModel,
  FilterOption,
  FilterParams,
  FilterVehicleMetaDataResponse,
  QueryParam,
  QueryParamWithReservationDetails,
  ReservationDetails,
  ReservationDetailsResponse,
  ReservationState,
  ReserveVehicleRequest,
  ReserveVehicleResponse,
  VehicleResponse,
  VehicleViewModel,
} from './vehicle.service.types';
import { formatLocalTime } from '@src/app/utils/time-utils';

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  private apiUrl = environment.baseApiUrl + '/vehicle';
  private apiKey = environment.vehicleApiKey;
  private _reservationDetails = new BehaviorSubject<ReservationDetails | null>(
    null
  );
  reservationDetails$ = this._reservationDetails.asObservable();
  lastEvaluatedKey?: string;
  private vehicles: VehicleViewModel[] = [];
  private dealers: DealerViewModel[] = [];
  vehicles$ = new BehaviorSubject<VehicleViewModel[]>([]);
  loadMoreVehicles$ = new BehaviorSubject<boolean>(false);
  loadMoreVehicleText$ = new BehaviorSubject<string>('Getting vehicles...');

  private dealerApiKey = environment.dealerApiKey;
  vinToImageUrl$ = new BehaviorSubject<Record<string, string>>({});
  private readonly dealerAPiEndPoint =
    environment.dealerBaseApiUrl + '/dealers/public/by-dealer-id';

  get reservationDetails(): ReservationDetails | null {
    return this._reservationDetails.value;
  }

  private get pickupDateTimeLocal(): string {
    if (!this.reservationDetails) return '';
    return this.pickupDateTime;
  }
  private get returnDateTimeLocal(): string {
    if (!this.reservationDetails) return '';
    return this.returnDateTime;
  }

  private get pickupDateTime(): string {
    if (!this.reservationDetails) return formatLocalTime(new Date());
    return getFormattedDateTime(
      this.reservationDetails.queryParams.pickupDate,
      this.reservationDetails.queryParams.pickupTime
    );
  }

  private get returnDateTime(): string {
    if (!this.reservationDetails) return formatLocalTime(new Date());
    return getFormattedDateTime(
      this.reservationDetails.queryParams.returnDate,
      this.reservationDetails.queryParams.returnTime
    );
  }

  private get searchApiUrl(): string {
    const filters = this.reservationDetails?.queryParams.filters;
    const hasFilters = filters && Object.keys(filters).length;
    const searchEndPoint = hasFilters ? 'opensearch/search' : 'search';
    return `${this.apiUrl}/public/${searchEndPoint}`;
  }

  constructor(private http: HttpClient) {
    this.watchVehiclesChangesAndFetchImages();
  }

  getDealerAddress(dealerId: string): string {
    return (
      this.dealers.find((dealer) => dealer.dealerId === dealerId)?.address || ''
    );
  }

  watchVehiclesChangesAndFetchImages(): void {
    this.vehicles$.subscribe((vehicles) => {
      vehicles.forEach((vehicle) => {
        const vinToImageUrl = this.vinToImageUrl$.value;
        if (!vinToImageUrl[vehicle.vin]) {
          this.fetchVehicleImage(vehicle.vin).subscribe((image) => {
            vinToImageUrl[vehicle.vin] = image;
            this.vinToImageUrl$.next(vinToImageUrl);
          });
        }
      });
    });
  }

  fetchVehicleImage(vin: string): Observable<string> {
    const dealerVehiclesAPiEndPoint =
      environment.dealerBaseApiUrl + '/vehicles/public/by-vin';
    return this.http
      .get<DealerImageResponse>(`${dealerVehiclesAPiEndPoint}?vin=${vin}`, {
        headers: new HttpHeaders().set(
          'x-api-key',
          environment.dealerVehicleApiKey
        ),
      })
      .pipe(map((res) => {
        if (res.promoImages && res.promoImages.length) return res.promoImages[0];
        return 'assets/images/vehicle-placeholder.png';
      }));
  }

  resetVehicles(): void {
    this.vehicles = [];
    this.vehicles$.next([]);
  }

  getVehicleFilterMetaData(): Observable<Record<string, FilterOption[]>> {
    return this.http
      .get<FilterVehicleMetaDataResponse>('assets/vehicle-filter-metadata.json')
      .pipe(map((data) => data.meta));
  }

  // initial search or when user refresh the browse vehicle page
  browseVehicles(
    searchVehicleRequest: QueryParam,
    initialSearch = true
  ): Observable<VehicleViewModel[]> {
    if (initialSearch) {
      searchVehicleRequest;
      this.updateReservation({
        ...this.reservationDetails,
        queryParams: searchVehicleRequest,
      } as ReservationDetails);
    }
    if (this.vehicles.length) return of(this.vehicles);
    return this.searchVehicles();
  }

  applyFilters(filters: FilterParams | null): Observable<VehicleViewModel[]> {
    this.loadMoreVehicleText$.next('Getting vehicles...');
    this.loadMoreVehicles$.next(true);
    this.updateReservation({
      ...this.reservationDetails,
      queryParams: {
        ...this.reservationDetails?.queryParams,
        filters,
      },
    } as ReservationDetails);

    return this.http
      .get<BrowserVehicleResponse | VehicleResponse[]>(this.searchApiUrl, {
        params: this.getHttpParamsFromObject(),
        ...this.httpOptions,
      })
      .pipe(
        finalize(() => this.loadMoreVehicles$.next(false)),
        map((response) => {
          this.vehicles = this.handleSearchVehicleResponse(response);
          this.vehicles$.next(this.vehicles);
          return this.vehicles;
        })
      );
  }

  clearFilters(): Observable<any> {
    this.updateReservation({
      ...this.reservationDetails,
      filters: undefined,
    } as ReservationDetails);
    return this.applyFilters(null);
  }

  searchVehicleInNewLocation(
    params: HandleFetchVehiclesInNewLocationParams
  ): Observable<VehicleViewModel[]> {
    this.loadMoreVehicleText$.next('Getting vehicles in new location...');
    this.loadMoreVehicles$.next(true);
    this.updateReservation({
      ...this.reservationDetails,
      queryParams: {
        ...this.reservationDetails?.queryParams,
        ...params,
      },
    } as ReservationDetails);

    return this.searchVehicles().pipe(
      finalize(() => this.loadMoreVehicles$.next(false))
    );
  }

  // pagination search
  getMoreVehicles(): Observable<VehicleViewModel[]> {
    const hasFilters = this.reservationDetails?.queryParams.filters;
    const params = this.getHttpParamsFromObject(true);
    if (!hasFilters && !this.lastEvaluatedKey) return of([]);
    return this.http
      .get<BrowserVehicleResponse | VehicleResponse[]>(this.searchApiUrl, {
        params,
        ...this.httpOptions,
      })
      .pipe(
        finalize(() => this.loadMoreVehicles$.next(false)),
        map((response) => {
          const vehicles = this.handleSearchVehicleResponse(response, false);
          if (!vehicles.length) return vehicles;
          this.vehicles = [...this.vehicles, ...vehicles];
          this.vehicles$.next(this.vehicles);
          return vehicles;
        })
      );
  }

  reserveVehicle(
    reservationDetails: ReservationDetails
  ): Observable<{ reservationId?: string }> {
    return this.http
      .post<ReserveVehicleResponse>(
        this.apiUrl + '/public/reserveByVin',
        this.getReserveVehicleRequest(
          reservationDetails.queryParams as QueryParamWithReservationDetails
        ),
        {
          ...this.httpOptions,
        }
      )
      .pipe(
        tap((res) =>
          this.updateReservation({
            ...reservationDetails,
            reservationId: res.data?.id,
            createdAt: res.data?.createdAt,
          })
        ),
        map((res) => ({ reservationId: res.data?.id, createdAt: res.data?.createdAt }))
      );
  }

  cancelReservation(): Observable<any> {
    if (!this.reservationDetails?.reservationId)
      return of({ statusCode: 404, message: 'Reservation details not found' });
    return this.http.post(
      this.apiUrl + '/public/cancel/reservation',
      {
        reservationId: this.reservationDetails?.reservationId,
      },
      this.httpOptions
    );
  }

  updateReservationUsingQueryParams(
    queryParams: QueryParamWithReservationDetails
  ): Observable<ReservationState> {
    const { reservationId, vin, dealerId } = queryParams;
    const reservationDetails$ = this.getReservationDetails(reservationId)
    const dealer$ = this.getDealerById(dealerId)
    const vehicleImage$ = this.fetchVehicleImage(vin).pipe(catchError(() => of('assets/images/vehicle-placeholder.png')));

    return forkJoin({reservationDetails$, dealer$, vehicleImage$ }).pipe(
      map((res) => {
        const { reservationDetails$, dealer$, vehicleImage$} = res;

        const totalPrice = this.getTotalPriceForRange(
          reservationDetails$.vehicle,
          reservationDetails$.reservation.startDateTime,
          reservationDetails$.reservation.endDateTime
        );
        const vehicleViewModel = this.vehicleResponseToVehicleViewModel(
          reservationDetails$.vehicle,
          totalPrice
        );
        vehicleViewModel.imageUrl = [vehicleImage$];

        const reservationDetails: ReservationDetails = {
          reservationId,
          vehicle: vehicleViewModel,
          customer: reservationDetails$.customer || undefined,
          reservationAddress: dealer$?.address,
          totalPrice: vehicleViewModel.totalPrice,
          createdAt: reservationDetails$.reservation.createdAt,
          isTestDrive: reservationDetails$.reservation.isTestDrive,
          queryParams,
        };
        this._reservationDetails.next(reservationDetails);
        return reservationDetails$.reservation.state;
      })
    );
  }
  updateReservationUsingReservationId(
    reservationId: string
  ): Observable<ReservationDetailsResponse> {
    const reservationDetails$ = this.getReservationDetails(reservationId);

    return forkJoin({ reservationDetails$ }).pipe(
      map((res) => {
        const { reservationDetails$ } = res;

        const reservationDetails: ReservationDetails = {
          ...this.reservationDetails,
          reservationId,
          reservationNumber: reservationDetails$.reservation.reservationNumber,
          amountReceived: reservationDetails$.reservation.amountReceived / 100,
          isTestDrive: reservationDetails$.reservation.isTestDrive,
          queryParams: this.reservationDetails
            ?.queryParams as QueryParamWithReservationDetails,
        };
        this._reservationDetails.next(reservationDetails);
        return reservationDetails$;
      })
    );
  }

  confirmBooking(): Observable<unknown> {
    if (!this.reservationDetails)
      return {
        statusCode: 404,
        message: 'Reservation details not found',
      } as any;

    const dealerGroup = sessionStorage.getItem('dealerGroup');

    return this.http.post(
      this.apiUrl + '/private/confirmBooking',
      {
        reservationId: this.reservationDetails?.reservationId,
        dealerGroup: dealerGroup ? JSON.parse(dealerGroup).group : null,
      },
      {
        ...this.httpOptions,
      }
    );
  }

  updateReservation(reservationDetails: ReservationDetails): void {
    this._reservationDetails.next(reservationDetails);
    this.saveReservationDetailsToLocalStorage();
  }

  updateReservationWithCustomerInfo(customerInfo: CustomerInfo): void {
    this._reservationDetails.next({
      ...(this.reservationDetails as ReservationDetails),
      customer: customerInfo,
    });
  }

  getDealerById(dealerId: string): Observable<DealerViewModel | undefined> {
    if (this.dealers.length) {
      const dealer = this.dealers.find(
        (dealer) => dealer.dealerId === dealerId
      );
      if (dealer) return of(dealer);
    }
    return this.http
      .get<Dealer>(this.dealerAPiEndPoint, {
        params: new HttpParams().set('dealerId', dealerId),
        headers: new HttpHeaders().set('x-api-key', this.dealerApiKey),
      })
      .pipe(
        catchError(() => of(DEALER_NOT_FOUND_MOCK)),
        map((response) => {
          if (!response.settings.businessHours) {
            response = {
              ...response,
              settings: {
                ...DEALER_NOT_FOUND_MOCK.settings,
              },
            };
          }
          const dealer = this.dealerToDealerViewModel(response, dealerId);
          this.dealers.push(dealer);
          return dealer;
        })
      );
  }

  getDealerGroup(group: string): Observable<DealerGroup | undefined> {
    return this.http
      .get<DealerGroup>(this.apiUrl + `/public/dealer/group/${group}`, this.httpOptions);
  }

  private getReservationDetails(
    reservationId: string
  ): Observable<ReservationDetailsResponse> {
    const encodedReservationId = encodeURIComponent(reservationId);
    return this.http.get<ReservationDetailsResponse>(
      this.apiUrl + `/public/reservation/${encodedReservationId}`,
      this.httpOptions
    );
  }

  private searchVehicles(): Observable<VehicleViewModel[]> {
    return this.http
      .get<BrowserVehicleResponse | VehicleResponse[]>(this.searchApiUrl, {
        params: this.getHttpParamsFromObject(),
        ...this.httpOptions,
      })
      .pipe(
        map((response) => {
          this.vehicles = this.handleSearchVehicleResponse(response);
          this.vehicles$.next(this.vehicles);
          return this.vehicles;
        })
      );
  }

  private handleSearchVehicleResponse(
    response: BrowserVehicleResponse | VehicleResponse[],
    replace = true
  ): VehicleViewModel[] {
    if ((response as any).length === undefined) {
      const res = response as BrowserVehicleResponse;
      this.lastEvaluatedKey = res.lastEvaluatedKey;
      return this.assignVehicles(res.vehicles, replace);
    } else {
      this.lastEvaluatedKey = undefined;
      const res = response as VehicleResponse[];
      return this.assignVehicles(res, replace);
    }
  }

  private assignVehicles(
    vehicles: VehicleResponse[],
    replace = true
  ): VehicleViewModel[] {
    const vehicleViewModels = vehicles.map((vehicle) =>
      this.vehicleResponseToVehicleViewModel(vehicle)
    );
    if (!replace) return vehicleViewModels;
    this.vehicles = vehicleViewModels;
    this.vehicles$.next(this.vehicles);
    return this.vehicles;
  }

  private getReserveVehicleRequest(
    reservationDetails: QueryParamWithReservationDetails
  ): ReserveVehicleRequest {
    const dealerGroup = sessionStorage.getItem('dealerGroup');
    return {
      pickuplatitude: +reservationDetails.lat,
      returnlatitude: +reservationDetails.lat,
      pickuplongitude: +reservationDetails.lng,
      returnlongitude: +reservationDetails.lng,
      vin: reservationDetails.vin,
      dealerId: reservationDetails.dealerId,
      startDateTime: this.pickupDateTimeLocal,
      endDateTime: this.returnDateTimeLocal,
      dealerGroup: dealerGroup ? JSON.parse(dealerGroup).group : null,
    };
  }

  private saveReservationDetailsToLocalStorage(): void {
    localStorage.setItem(
      'reservation',
      JSON.stringify(this._reservationDetails.value)
    );
  }

  private getHttpParamsFromObject(loadMoreVehicles = false): HttpParams {
    if (!this.reservationDetails) return new HttpParams();
    const queryParams = this.reservationDetails.queryParams;
    let params = new HttpParams()
      .set('startDateTime', this.pickupDateTimeLocal)
      .set('endDateTime', this.returnDateTimeLocal)
      .set('renterAge', queryParams.renterAge)
      .set('lat', queryParams.lat)
      .set('lon', queryParams.lng)
      .set('limit', queryParams.limit)
      .set('radius', queryParams.radius);

    if (queryParams.dealerId) params = params.set('dealerId', queryParams.dealerId);  
    if (queryParams.dealerGroup) params = params.set('dealerGroup', queryParams.dealerGroup);

    params = queryParams.filters
      ? this.getHttpParamsForFilters(params, loadMoreVehicles)
      : this.getHttpParamsWithoutFilters(params);

    return params;
  }

  private getHttpParamsForFilters(
    params: HttpParams,
    loadMoreVehicles: boolean
  ): HttpParams {
    const queryParams = this.reservationDetails?.queryParams;
    const filters = queryParams?.filters;
    if (!filters) return params;
    Object.keys(filters).forEach((key) => {
      const values = filters?.[key];
      if (values) {
        if (typeof values === 'string' || typeof values === 'number') {
          const value =
            key === 'priceMin' || key === 'priceMax' ? +values * 100 : values;
          params = params.set(key, value);
          return;
        }

        params = params.set(key, values.join(','));
      }
    });
    params = params.set('size', queryParams.limit || DEFAULT_LIMIT);
    params = params.set('from', loadMoreVehicles ? this.vehicles.length : 0);
    params = params.delete('limit');
    params = params.delete('lastEvaluatedKey');
    return params;
  }

  private getHttpParamsWithoutFilters(params: HttpParams): HttpParams {
    params = params.delete('from');
    params = params.delete('size');
    if (this.lastEvaluatedKey)
      params = params.set('lastEvaluatedKey', this.lastEvaluatedKey);

    return params;
  }

  private dealerToDealerViewModel(
    dealer: Dealer,
    dealerId: string
  ): DealerViewModel {
    return {
      dealerId,
      name: dealer.name,
      address: `${dealer.address}, ${dealer.city}, ${dealer.state}, ${dealer.zip}`,
      settings: {
        dealerLogoUrl: dealer.settings.dealerLogoUrl,
        openingHours: dealer.settings.businessHours.entries,
      },
    };
  }

  private vehicleResponseToVehicleViewModel(
    vehicle: VehicleResponse,
    totalReservationPrice?: number
  ): VehicleViewModel {
    const dailyPriceInDollar = this.vehicleDailyPrice(vehicle);
    const weeklyPriceInDollar = this.vehicleWeeklyPrice(vehicle);
    const monthlyPriceInDollar = this.vehicleMonthlyPrice(vehicle);

    const totalPrice = getTotalPrice(
      dailyPriceInDollar,
      weeklyPriceInDollar,
      monthlyPriceInDollar,
      this.pickupDateTime,
      this.returnDateTime
    );

    return {
      ...vehicle,
      totalPrice: totalReservationPrice || totalPrice,
      dailyPrice: dailyPriceInDollar,
      weeklyPrice: weeklyPriceInDollar,
      monthlyPrice: monthlyPriceInDollar,
      location: {
        lat: vehicle.location.lat,
        lng: vehicle.location.lon,
      },
    };
  }

  private vehicleDailyPrice(vehicle: VehicleResponse): number {
    const dailyPrice =
      vehicle.prices.find((price) => price.type === 'daily_rate')?.value || 0;
    return +dailyPrice / 100;
  }

  private vehicleWeeklyPrice(vehicle: VehicleResponse): number {
    const weeklyPrice =
      vehicle.prices.find((price) => price.type === 'weekly_rate')?.value || 0;
    return +weeklyPrice / 100;
  }

  private vehicleMonthlyPrice(vehicle: VehicleResponse): number {
    const monthlyPrice =
      vehicle.prices.find((price) => price.type === 'monthly_rate')?.value || 0;
    return +monthlyPrice / 100;
  }

  private getTotalPriceForRange(
    vehicle: VehicleResponse,
    pickupDateTime: string,
    returnDateTime: string
  ): number {
    const dailyPriceInDollar = this.vehicleDailyPrice(vehicle);
    const weeklyPriceInDollar = this.vehicleWeeklyPrice(vehicle);
    const monthlyPriceInDollar = this.vehicleMonthlyPrice(vehicle);
    return getTotalPrice(
      dailyPriceInDollar,
      weeklyPriceInDollar,
      monthlyPriceInDollar,
      pickupDateTime,
      returnDateTime
    );
  }

  private get httpOptions() {
    return { headers: new HttpHeaders().set('x-api-key', this.apiKey) };
  }
}
